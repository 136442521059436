<template>
  <section>
    <div class="name">
      {{ user.role }}: {{ user.first_name }} {{ user.last_name }}
    </div>
    <div class="task-area">
      <div class="upper">
        <div class="task-title-area">
          <div class="task-title">
            {{ topic }}
          </div>
          <div class="tasks-completed">
            {{ count }}/{{ checklist.tasks.length }} tasks complete
          </div>
        </div>
        <div @click="handleClick" :class="statusClass">{{ statusText }}</div>
      </div>
      <div class="subtask-area">
        <div class="table-header">
          <div class="header-item">Task</div>
          <div v-if="hasSubtask" class="header-item">Subtask</div>
          <div class="header-item">Status</div>
        </div>
        <div v-for="task in checklist.tasks" :key="task" class="subtask">
          <div class="subtask-item">{{ task.name }}</div>
          <div v-if="task.subtasks" class="subtask-item">
            <div>
              <span v-for="(item, index) in task.subtasks" :key="item"
                >{{ item.name
                }}<span v-if="index + 1 != task.subtasks?.length">, </span>
              </span>
            </div>
          </div>
          <div v-if="task.progresses" class="subtask-status subtask-item">
            <img
              :src="getIcon(task.progresses[0]?.status)"
              class="status-icon"
            />
            <div class="status-name">
              {{ getStatusText(task.progresses[0]?.status) }}
            </div>
          </div>
          <div v-else class="subtask-status subtask-item">
            <img :src="getIcon('available')" class="status-icon" />
            <div class="status-name">{{ "To-Do" }}</div>
          </div>
        </div>
      </div>
      <div class="assistance-area">
        <div class="assistance-title">Assistance Request Messages</div>
        <div v-for="item in assistances" :key="item" class="assistance-item">
          <div class="assistance-item-left">
            <!-- <div class="assistance-date">date/date/date</div> -->
            <div class="assistance-item-title">{{ item.task.name }}</div>
            <div class="assistance-item-description">{{ item.problem }}</div>
          </div>
          <div class="assistance-item-right">
            <div
              v-if="item.task.progresses[0].status === 'attention-required'"
              class="resolve"
              @click="resolve(item)"
            >
              Resolve
            </div>
            <div v-else class="resolved">
              <img :src="check" alt="" /> Resolved
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import attentionRequired from "@/assets/icons/attention-required-status.svg";
import check from "@/assets/icons/small-check.png";
import ChecklistAPI from "@/api/ChecklistAPI";
import UserAPI from "@/api/UserAPI";
import { onMounted, ref } from "vue";
import completed from "@/assets/icons/completed-status.svg";
import inReview from "@/assets/icons/in-review-status.svg";
import available from "@/assets/icons/available-status.svg";

const router = useRouter();
const route = useRoute();
const store = useStore();
const checklist = ref();
const user = ref();
const topic = ref();
const hasSubtask = ref(false);
const assistances = ref([]);
const status = ref();
const getTasks = async () => {
  assistances.value = [];
  const userID = route.params.taskID;
  topic.value = decodeURIComponent(route.query.task);
  console.log("topic:", topic.value);
  const trainees = await UserAPI.getMyTrainees();
  user.value = trainees.reports.find((item) => +item.id === +userID);
  user.value.first_name = user.value.preferred_name
    ? user.value.preferred_name
    : user.value.employee_first_name;
  user.value.last_name = user.value.preferred_last_name
    ? user.value.preferred_last_name
    : user.value.employee_last_name;
  if (!user.value.role) {
    if (user.value.job_profile_id === "HDISIC") user.value.role = "IC";
    if (user.value.job_profile_id === "SHDISD") user.value.role = "DIM";
    if (user.value.job_profile_id === "SBHDEI") user.value.role = "BIM";
    if (user.value.job_profile_id === "HDISAM") user.value.role = "AIS";
  }
  console.log(user.value);
  console.log(userID);

  const list = await ChecklistAPI.getTrainee(userID, user.value.role);
  const items = [];
  list.forEach((item) => {
    const included = items.find((task) => task.name === item.name);
    if (!included) items.push(item);
    else {
      item?.tasks.forEach((task) => {
        included?.tasks.push(task);
      });
    }
    const thisTask = items.find((task) => task.name === item.name);
    thisTask?.tasks?.sort(function (a, b) {
      return a.order - b.order;
    });
  });
  items.forEach((item) => {
    const subs = [];
    item.tasks.forEach((task) => {
      const included = subs?.find((sub) => sub.name === task.name);
      if (!included) {
        subs.push(task);
      } else {
        task.subtasks?.forEach((sub) => {
          included.subtasks?.push(sub);
        });
        task?.progresses?.forEach((sub) => {
          included.progresses?.push(sub);
        });
      }
    });
    console.log("sub:", subs);
    item.tasks = subs;
  });
  checklist.value = items.find((item) => item.name === topic.value);
  console.log(checklist);
  let assistanceList = await ChecklistAPI.getAssistanceByID(userID);
  checklist.value.tasks?.forEach((item) => {
    // if (item.progresses) {
    //   if (item.progresses[0] === "attention-required") {
    const ass = assistanceList.find((assist) => assist.task_id === item.id);
    if (ass) {
      ass.task = item;
      assistances.value.push(ass);
    }
    // }
    if (item.subtasks) {
      hasSubtask.value = true;
    }
  });
  getStatusClass();
};
function getStatusText(status) {
  if (status === "completed") return "Complete";
  if (status === "attention-required") return "Needs Assitance";
  if (status === "in-review") return "Ready for Review";
  if (status === "available") return "To-Do";
}
onMounted(async () => {
  await getTasks();
  //   item.progresses.forEach(progress=>{
  //       item.status = false

  //   })
});
const statusClass = ref();
const statusText = ref();
const count = ref(0);
const getStatusClass = () => {
  let completed = true;
  let in_review = true;
  let assistance = false;
  checklist.value.tasks.forEach((task) => {
    if (task.progresses) {
      if (task.progresses[0].status !== "completed") completed = false;
      if (task.progresses[0].status === "completed") count.value++;
      if (task.progresses[0].status !== "in-review") in_review = false;
      if (task.progresses[0].status === "attention-required") assistance = true;
    } else {
      completed = false;
      in_review = false;
    }
  });
  if (completed) {
    statusClass.value = "completed";
    statusText.value = "Completed";
  } else if (in_review) {
    statusClass.value = "in-review";
    statusText.value = "Mark Complete";
  } else {
    statusClass.value = "to-do";
    statusText.value = "To-Do";
  }
};
const resolve = async (item) => {
  const updatedTask = {
    user_id: +route.params.taskID,
    task_id: item.task.id,
    status: "in-review",
    subtask_status: "unavailable",
  };
  await ChecklistAPI.updateTask(updatedTask);

  await getTasks();
};
const handleClick = async () => {
  if (statusClass.value === "in-review") {
    checklist.value.tasks.forEach(async (task) => {
      const updatedTask = {
        user_id: +route.params.taskID,
        task_id: task.id,
        status: "completed",
        subtask_status: "unavailable",
      };
      await ChecklistAPI.updateTask(updatedTask);
    });
    await getTasks();
  }
};
const getIcon = (status) => {
  if (status === "completed") return completed;
  if (status === "attention-required") return attentionRequired;
  if (status === "in-review") return inReview;
  if (status === "available") return available;
};
</script>

<style scoped>
.name {
  color: #ff8217;
  font-size: 18px;
  font-family: Helvetica Neue LT Std;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.header-item {
  text-align: left;
}
.subtask-status {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-left: auto;
}
.status-icon {
  margin-left: 5px;
}
.task-area {
  border-radius: 20px;
  background: #fff;
  display: flex;
  width: 958px;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
}
.subtask-area {
  border-radius: 10px;
  border: 1px solid var(--light-grey, #e4e4e4);
}
.subtask {
  display: flex;
  width: 878px;
  padding: 10px 20px;
  align-items: center;
  gap: 30px;
  justify-content: space-around;
  border-top: 1px solid var(--light-grey, #e4e4e4);
}
.completed {
  color: var(--complete, #abe2c8);
  text-align: center;
  font-size: 18px;
  font-family: Helvetica Neue LT Std;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}
.to-do {
  color: darkgray;
  text-align: center;
  font-size: 18px;
  font-family: Helvetica Neue LT Std;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}
.in-review {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: var(--darker-orange, #e87524);
  color: #fff;
  text-align: center;
  font-family: Helvetica Neue LT Std;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
  cursor: pointer;
}

.status {
  margin-left: auto;
}
.upper {
  display: flex;
}
.task-title {
  color: #5f6562;
  font-size: 24px;
  font-family: Helvetica Neue LT Std;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.table-header {
  display: flex;
  width: 878px;
  padding: 10px 20px;
  align-items: center;
  gap: 30px;
  justify-content: space-around;
  /* border-top: 1px solid var(--light-grey, #E4E4E4); */
  background: var(--system-light-grey, #f4f6f5);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.subtask-item {
  text-align: left;
  width: 32%;
  margin-left: 99px;
  /* justify-content: center; */
}
.status-icon {
  margin-right: 10px;
}
.assistance-area {
  border-radius: 10px;
  border: 1px solid var(--light-grey, #e4e4e4);
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}
.resolve {
  display: flex;
  width: 100px;
  height: 39px;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  color: var(--system-light-grey, #f4f6f5);
  text-align: center;

  font-family: Helvetica Neue LT Std;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;

  border-radius: 24px;
  background: var(--alert, #b32d22);
}

.resolved {
  display: flex;
  width: 100px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  color: #fff;
  text-align: center;
  font-family: Helvetica Neue LT Std;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  border-radius: 24px;
  background: #ddd;
}
.assistance-title {
  color: var(--dark-grey, #5f6562);
  font-family: Helvetica Neue LT Std;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.assistance-item {
  display: flex;
  padding-bottom: 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--light-grey, #e4e4e4);
  width: 100%;
  padding: 30px;
}
.assistance-date {
  color: var(--medium-grey, #908f8f);
  font-family: Helvetica Neue LT Std;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.assistance-item-title {
  color: var(--alert, #b32d22);
  font-family: Helvetica Neue LT Std;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.assistance-item-description {
  color: #5f6562;
  font-family: Helvetica Neue LT Std;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 142%;
}
</style>
